<template>
    <div>
        <slot
            :categories="categories"
            :update-categories="updateCategories"
        ></slot>
    </div>
</template>

<script setup>
import { ref } from 'vue';

const props = defineProps({
    categories: {
        type: Array,
        required: true,
    },
});

const categories = ref(props.categories);

const updateCategories = (event) => {
    if (categories.value.includes(event.target.value)) {
        categories.value = categories.value.filter((item) => {
            return item !== event.target.value;
        });

        // eslint-disable-next-line
        submit();

        return;
    }

    categories.value.push(event.target.value);

    // eslint-disable-next-line
    submit();
};

const submit = () => {
    const searchParams = new URLSearchParams();

    if (categories.value.length) {
        searchParams.append('category', categories.value.join(','));
    }

    window.location.href = `?${searchParams.toString()}`;
};

</script>
