// Remove our preload class from the body, preventing early CSS transitions
import '@utils/preload';

// CSS needs to be imported here as it's treated as a module
import '@/scss/style.scss';
import '@splidejs/splide/css/core';
import '@splidejs/splide/css/skyblue';

// ================================================
// All third-party, globally-required libraries should be loaded here
// ================================================

// Support for lazyload images (and bg images)
import 'lazysizes';
import 'lazysizes/plugins/bgset/ls.bgset';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';

// An lightweight paralax animation library
import lax from 'lax.js';

// Flickity - please use `data-flickity` options to configure
import 'flickity';
import 'flickity-bg-lazyload';

// An excellent, responsive and lightweight lightbox
import 'spotlight.js';

// Maps
import GoogleMapEmbed from '@components/GoogleMapEmbed.vue';

import LaxScrollAnimation from '@components/LaxScrollAnimation.vue';
import ListFilters from '@components/ListFilters.vue';

// ================================================
// SUI Components
// ================================================

import { Splide, SplideSlide, SplideTrack } from '@splidejs/vue-splide';

// ================================================
// Setup Vue3 - Use only if this project needs Vue!
// ================================================
import { createApp } from 'vue';

// Import all globally-available components here. Be responsible please.
import {
    Dialog,
    DialogDescription,
    DialogOverlay,
    DialogPanel,
    DialogTitle,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Popover,
    PopoverButton,
    PopoverPanel,
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
    TransitionChild,
    TransitionRoot,
} from '@headlessui/vue';

createApp({
    delimiters: ['${', '}'],

    components: {
        HuiDisclosure: Disclosure,
        HuiDisclosureButton: DisclosureButton,
        HuiDisclosurePanel: DisclosurePanel,
        HuiDialog: Dialog,
        HuiDialogPanel: DialogPanel,
        HuiDialogOverlay: DialogOverlay,
        HuiDialogTitle: DialogTitle,
        HuiDialogDescription: DialogDescription,
        HuiPopover: Popover,
        HuiPopoverButton: PopoverButton,
        HuiPopoverPanel: PopoverPanel,
        HuiTabGroup: TabGroup,
        HuiTabList: TabList,
        HuiTab: Tab,
        HuiTabPanels: TabPanels,
        HuiTabPanel: TabPanel,
        GoogleMapEmbed,
        LaxScrollAnimation,
        SplideCarousel: Splide,
        SplideCarouselTrack: SplideTrack,
        SplideCarouselSlide: SplideSlide,
        ListFilters,
        TransitionRoot,
        TransitionChild,
    },

    provide() {
        lax.init();

        lax.addDriver('scrollY', () => {
            return window.scrollY;
        });

        return {
            lax,
        };
    },

    data() {
        return {
            navActive: false,
            siteSearch: false,
            openModal: true,
        };
    },

    mounted() {},

    methods: {
        toggleModal() {
            this.openModal = !this.openModal;

            document.cookie = 'modal=modal; path=/; max-age=2592000;';
        },
    },
}).mount('#app');
